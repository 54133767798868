.custom-card {
  background-color: #fff;
  border-radius: 0.428rem;
  padding-top: 5px;
  min-width: 180px;
  width: fit-content;
  height: 150px;

  //   assign margin right not last items
}

.custom-card-text {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  text-align: center;
  background-color: #fff;
  border-radius: 0.428rem;
}
.custom-card-text-value {
  color: $info;
}
.custom-card-text:not(:last-child) {
  margin-right: 1rem;
}

.dark-layout {
  .custom-card {
    background-color: #283046;
    border-radius: 0.428rem;
    padding-top: 5px;
    min-width: 180px;
    width: fit-content;
    height: 150px;

    //   assign margin right not last items
  }

  .custom-card-text {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.5rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    text-align: center;
    background-color: #283046;
    border-radius: 0.428rem;
  }
  .custom-card-text-value {
    color: $info;
  }
  .custom-card-text:not(:last-child) {
    margin-right: 1rem;
  }

  .pieChartContainer {
    width: 200px;
    height: 120px;
  }
}
