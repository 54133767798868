// Component Pagination
// ========================================================================
.pagination {
  &.react-paginate {
    // arrange line-height
    .page-item {
      &:not(.prev-item),
      &:not(.next-item),
      &:not(.first),
      &:not(.last),
      &:not(.active) {
        .page-link {
          line-height: 1.2;
        }
      }
    }

    .page-item.prev,
    .page-item.next {
      a.page-link:before,
      a.page-link:after {
        height: 16.5px;
        margin-top: 1px;
      }
    }
    &.pagination-sm {
      .page-item.prev,
      .page-item.next {
        a.page-link:before,
        a.page-link:after {
          height: 15px;
          width: 8px;
        }
      }
    }
    &.pagination-lg {
      .page-item.prev,
      .page-item.next {
        a.page-link:before,
        a.page-link:after {
          height: 19px;
        }
      }
    }
  }

  &.no-navigation {
    li:first-of-type,
    li:last-of-type {
      display: none;
    }
  }
}
