@import "../core/mixins/main-menu-mixin.scss";
.header-warning-wrapper {
  padding: 1rem;
  // gap from bottom 1 rem
  width: 100%;

  .banner {
    border-radius: 12px;
    display: flex;
    gap: 12px;
    font-size: 14px;
    font-weight: 500;
    padding: 12px;
    margin-bottom: 1rem;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: space-between;
  }

  .icon-text-wrapper {
    display: flex;
    gap: 12px;
    align-items: center;
    flex-wrap: nowrap;
  }

  @media (max-width: 991px) {
    .banner {
      flex-wrap: wrap;
    }
  }

  .icon {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 24px;
    margin: auto 0;
  }

  .message {
    color: $black-600;
    font-family: Inter, sans-serif;
  }

  @media (max-width: 991px) {
    .message {
      max-width: 100%;
    }
  }

  .actionWrapper {
    display: flex;
    padding-left: 16px;
    gap: 4px;
    white-space: nowrap;
    justify-content: space-between;
  }

  @media (max-width: 991px) {
    .actionWrapper {
      white-space: initial;
    }
  }
  &.info-alert {
    .actionWrapper {
      float: right;
      .dismissButton {
        color: $blue-100;
        font-family: Inter, sans-serif;
        margin: auto 0;
        margin-right: 12px;
      }

      .startButton {
        font-family: Inter, sans-serif;
        justify-content: center;
        border-radius: 8px;
        background-color: $blue-100;
        color: $white;
        padding: 8px 16px;
      }
    }
  }

  &.error-alert {
    .actionWrapper {
      .dismissButton {
        color: $danger;
        font-family: Inter, sans-serif;
        margin: auto 0;
        margin-right: 12px;
      }

      .startButton {
        font-family: Inter, sans-serif;
        justify-content: center;
        border-radius: 8px;
        background-color: $danger;
        color: $white;
        padding: 8px 16px;
      }
    }
  }

  &.success-alert {
    .actionWrapper {
      .dismissButton {
        color: $success;
        font-family: Inter, sans-serif;
        margin: auto 0;
        margin-right: 12px;
      }

      .startButton {
        font-family: Inter, sans-serif;
        justify-content: center;
        border-radius: 8px;
        background-color: $success;
        color: $white;
        padding: 8px 16px;
      }
    }
  }

  &.warning-alert {
    .actionWrapper {
      .dismissButton {
        color: $primary;
        font-family: Inter, sans-serif;
        margin: auto 0;
        margin-right: 12px;
      }

      .startButton {
        font-family: Inter, sans-serif;
        justify-content: center;
        border-radius: 8px;
        background-color: $primary;
        color: $white;
        padding: 8px 16px;
      }
    }
  }

  @media (max-width: 991px) {
    .startButton {
      white-space: initial;
    }
  }

  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
}
