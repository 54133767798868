@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* Fonts */
  --fs-h1: 3rem;
  --lh-h1: 3.125rem;

  --fs-h2: 2.4375rem;
  --lh-h2: 2.9375rem;

  --fs-h3: 1.9375rem;
  --lh-h3: 2.375rem;

  --fs-h4: 1.5625rem;
  --lh-h4: 1.875rem;

  --fs-h5: 1.25rem;
  --lh-h5: 1.5rem;

  --fs-body: 1rem;
  --lh-body: 1.1875rem;
  --fs-body-2: 0.875rem;
  --lh-body-2: 1.0625rem;

  --fs-body-3: 0.75rem;
  --lh-body-3: 0.9375rem;

  --fs-body-4: 0.625rem;
  --lh-body-4: 0.75rem;

  --fs-caption: 0.5rem;
  --lh-caption: 0.625rem;

  /* Colors */
  --primary: #1b242d;
  --primary-lighten-2: #a4a7ab;
  --primary-lighten-1: #5f666c;
  --primary-base: #1b242d;
  --primary-darken-1: #13191f;
  --primary-darken-2: #0b0e12;

  --secondary: #f7a71c;
  --secondary-lighten-2: #faca77;
  --secondary-lighten-1: #f9b949;
  --secondary-base: #f7a71c;
  --secondary-darken-1: #c68616;
  --secondary-darken-2: #946411;

  --black-base: #000000;
  --black-50: #f6f6f6;
  --black-100: #e2e2e2;
  --black-200: #d4d4d4;
  --black-400: #242629;
  --black-500: #0a0a0c;

  --gray: #6d6d6d;
  --gray-25: #fafafa;
  --gray-50: #f0f1f3;
  --gray-100: ##d1d4da;
  --gray-200: #babfc8;
  --gray-300: #9aa6b8;
  --gray-400: #6d6d6d;
  --gray-500: #697488;
  --gray-700: #4b5261;

  --white: #ffffff;

  --success: #16a34a;
  --success-50: #f0fdf4;

  --error: #dc2626;
  --error-50: #fef2f2;

  --warning: #f8aa1c;
  --warning-50: #fef7e8;

  --blue: #2563eb;
  --blue-50: #eff6ff;
}

/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .range-traces[type="range"]::-webkit-slider-thumb {
    width: 15px;
    -webkit-appearance: none;
    height: 15px;
    border-radius: 50%;
    cursor: ew-resize;
    background: var(--success);
  }

  .range-logs[type="range"]::-webkit-slider-thumb {
    width: 15px;
    -webkit-appearance: none;
    height: 15px;
    border-radius: 50%;
    cursor: ew-resize;
    background: var(--secondary-base);
  }

  .range-metrics[type="range"]::-webkit-slider-thumb {
    width: 15px;
    -webkit-appearance: none;
    height: 15px;
    border-radius: 50%;
    cursor: ew-resize;
    background: var(--blue);
  }

  .range-user-simulation[type="range"]::-webkit-slider-thumb {
    width: 15px;
    -webkit-appearance: none;
    height: 15px;
    border-radius: 50%;
    cursor: ew-resize;
    background: var(--error);
  }
}

/*Firefox*/

.range-traces::-moz-range-thumb {
  -moz-appearance: none;
  border: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  cursor: ew-resize;
  background: var(--primary-base);
}

.range-logs::-moz-range-thumb {
  -moz-appearance: none;
  border: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  cursor: ew-resize;
  background: var(--secondary-base);
}

.range-metrics::-moz-range-thumb {
  -moz-appearance: none;
  border: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  cursor: ew-resize;
  background: var(--blue);
}

.range-user-simulation::-moz-range-thumb {
  -moz-appearance: none;
  border: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  cursor: ew-resize;
  background: var(--error);
}

.polygon-fill {
  background-image: url("./assets/new-design/polygon-repeat-white.png");
  background-size: 31px 29px;
  background-repeat: repeat-x repeat-y;
}
