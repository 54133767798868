/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
@import "../../@core/scss/base/bootstrap-extended/include"; // Bootstrap includes
@import "../../@core/scss/base/components/include";

.scenarioStepCard {
  box-shadow: none !important;
  width: 100%;
  border: none;
  background-color: white;

  .envValueTypeColor {
    color: $theme-dark-text-muted-color;
  }

  &.shortPadding {
    padding: 0.5rem;
    padding-top: 0.75rem;
  }

  padding: $card-spacer-x;
}

.icon-card {
  border: 1px solid transparent;
  &.active {
    border-color: $primary;
    i,
    svg {
      color: $primary;
    }
  }
}

.ucFirst::first-letter {
  text-transform: capitalize;
}

.autoSave {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.z-max {
  position: relative !important;
  z-index: 998;
}

.mw100 {
  min-width: 100px;
}

.mw140 {
  min-width: 140px;
}

.mw150 {
  min-width: 240px;
}

.fullWidth {
  width: 100%;
}

.w75 {
  width: 75%;
}

.w50 {
  width: 50%;
}

.h50 {
  height: 90%;
}
.h-inherit {
  height: inherit;
}

.h-100-wMargin {
  height: calc(100% - 2rem);

  margin-bottom: 1rem;
}

.cursorPointer {
  cursor: pointer;
}

.cursorInitial {
  cursor: initial;
}

.cursorDefault {
  cursor: default;
}

.clearPadding {
  padding: 0 !important;
}

.clearMargin {
  margin: 0;
}

.clearShadow {
  box-shadow: none !important;
}

.defaultShadow {
  box-shadow: 4px 4px 18px -4px #4bd07e;
}

.clearBg {
  background-color: transparent !important;
}

.p5 {
  padding: 5px;
}

.p7 {
  padding: 7px;
}

.p3 {
  padding: 3px;
}

.pt0 {
  padding-top: 0;
}

.pt3 {
  padding-top: 3px;
}

.pt8 {
  padding-top: 8px;
}

.pt1rem {
  padding-top: 1rem !important;
}

.pt2rem {
  padding-top: 2rem !important;
}

.pb1rem {
  padding-bottom: 1rem !important;
}

.pb2rem {
  padding-bottom: 2rem !important;
}

.pb0 {
  padding-bottom: 0;
}

.pb3 {
  padding-bottom: 3px;
}

.pb5 {
  padding-bottom: 5px;
}

.pb7 {
  padding-bottom: 7px;
}

.pl-125rem {
  padding-left: 1.25rem;
}

.pr-125rem {
  padding-right: 1.25rem;
}

.pl2rem {
  padding-left: 2rem;
}

.p1rem {
  padding: 1rem;
}

.pr0 {
  padding-right: 0;
}

.pl18rem {
  padding-left: 1.8rem;
}

.pr25rem {
  padding-right: 2.5rem;
}

.pr2rem {
  padding-right: 2rem !important;
}

.pl0 {
  padding-left: 0 !important;
}

.px3 {
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.p05rem {
  padding: 0.5rem;
}

.ml3 {
  margin-left: 3px;
}

.ml5 {
  margin-left: 5px;
}

.ml7 {
  margin-left: 7px;
}

.ml10 {
  margin-left: 10px;
}

.ml-auto {
  margin-left: auto;
}

.ml2rem {
  margin-left: 2rem;
}

.mb1-5 {
  margin-bottom: 1.5px;
}

.mb3 {
  margin-bottom: 3px;
}
.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb1rem {
  margin-bottom: 1rem !important;
}

.mb2rem {
  margin-bottom: 2rem !important;
}

.mb05rem {
  margin-bottom: 0.5rem;
}

.mb0 {
  margin-bottom: 0;
}

.mt3 {
  margin-top: 3px;
}

.mt5 {
  margin-top: 5px;
}

.mt7 {
  margin-top: 7px;
}

.mtn7 {
  margin-top: -7px;
}

.mt10 {
  margin-top: 10px;
}

.mx1 {
  margin-left: 1px;
  margin-right: 1px;
}

.mx3 {
  margin-left: 3px;
  margin-right: 3px;
}

.mx7 {
  margin-left: 7px;
  margin-right: 7px;
}

.mtn16 {
  margin-top: -16px;
}

.mt1rem {
  margin-top: 1rem;
}

.mt05rem {
  margin-top: 0.5rem;
}
.mt105rem {
  margin-top: 1.5rem;
}

.ml1rem {
  margin-left: 1rem;
}

.mt2rem {
  margin-top: 2rem;
}

.mr-025 {
  margin-right: 0.25rem;
}

.mr1rem {
  margin-right: 1rem;
}

.mr5 {
  margin-right: 5px;
}

.mr3 {
  margin-right: 3px;
}

.mr10 {
  margin-right: 10px;
}

.m1rem {
  margin: 1rem;
}

.m05rem {
  margin: 0.5rem;
}

.mw-30 {
  min-width: 30px;
  width: auto;
}

.inlineText {
  white-space: pre-line;
}

.no-wrap {
  white-space: -moz-no-wrap; /* Firefox */
  white-space: -o-no-wrap; /* Opera */
  white-space: nowrap; /* Chrome */
}

.displayFlex {
  display: flex;
}

.flexWrap {
  flex-wrap: wrap;
}

.no-flexWrap {
  flex-wrap: nowrap;
}
.noBorderRadius {
  border-radius: 0;
}

.borderPrimary {
  border-color: $primary;
}

.borderSecondary {
  border-color: $secondary;
}

.border-bottom-sm {
  border-bottom: 0.5px solid;
}

.border-bottom-md {
  border-bottom: 1px solid;
}

.border-bottom-xl {
  border-bottom: 3px solid;
}

.borderBottomThick {
  border-bottom: 2px solid;
}

.settingsSubTitle {
  font-size: 0.8rem;
  color: #979999;
}

.subTitle {
  font-size: 0.8rem;
  color: #979999;
}

.settingsAlignRight {
  display: flex;
  justify-content: flex-end;
}

.alignRight {
  display: flex;
  justify-content: flex-end;
}

.justifyCenter {
  justify-content: center;
}

.alignFlexStart {
  align-items: flex-start;
}

.alignCenter {
  display: flex;
  align-items: center;
}

.alignBaseline {
  align-items: baseline;
}

.textAlignCenter {
  text-align: center;
}

.textAlignLeft {
  text-align: left;
}

.stepDetailTitle {
  color: #c3beb9;
  margin-bottom: 10px;
  font-weight: 600;
}

.stepDetailsTitleWithoutAlignment {
  font-weight: 600;
  color: #c3beb9;
}

.boldText {
  font-weight: 750;
}

.smallCardTitle {
  font-size: 1rem !important;
}

.justify-content-space-evenly {
  justify-content: space-evenly;
}

.justify-content-space-between {
  justify-content: space-between;
}

.flexDirectionRowReverse {
  display: flex;
  flex-direction: row-reverse;
}

.testSuiteBalanceBox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flexDirectionColumn {
  display: flex;
  flex-direction: column;
}

.select__placeholder {
  color: #c3beb9 !important;
}

.noBorderRadius .select__control {
  border-radius: 0 !important;
}

.popularPackage {
  border: 1px solid $primary;
}

.pricingBadge {
  position: absolute;
  right: 1rem;
}
.tooltip {
  pointer-events: none;
}
.tooltip-inner {
  max-width: 300px;
}

.fileButtonMultipart {
  border-color: #dedad6 !important;
  padding: 0.5rem 1rem;
  z-index: 0 !important;
  background-color: transparent !important;
}

.fileContentMultipartSimple {
  width: 81.9%;
  height: 100%;
  border: 1px solid #3b4253 !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.7rem;
}

.fileContentBinaryMultipart {
  width: 81.9%;
  height: 100%;
  border: 1px solid #3b4253 !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.fileTextBinaryMultipart {
  padding: 0.2rem;
  border: 1px solid #3b4253;
  border-radius: 0.25rem;
  font-size: 0.85rem;
  margin-left: 8px;
}

.fileContentMultipart {
  width: 81.9%;
  height: 100%;
  border: 1px solid #3b4253 !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.fileTextMultipart {
  padding: 0.2rem;
  border: 1px solid #dedad6;
  border-radius: 0.25rem;
  font-size: 0.85rem;
  margin-left: 10px;
}

.fileRemoveButtonMultipart {
  padding: 0;
  border: none !important;
  background-color: transparent !important;
  margin-bottom: 0.2rem;
  margin-left: 0.4rem;
}

.saveAsTestPlan {
  width: 100%;
  padding: 1rem;
  border: 2px dashed rgb(208, 208, 208);
  margin-bottom: 2rem;
}

.balanceWidgetWrapper {
  display: flex;
  align-items: baseline;
  row-gap: 0.12rem;
}

//
// Spinner
//
.loading {
  width: 40px !important;
  height: 40px !important;
}
.effect-1,
.effect-2,
.effect-3 {
  border-left: 2px solid $primary !important;
}

//
// Map CSS
//
$locationSelectedColor: #f89f44;
$locationUnselectedColor: #d1d5db;
$locationNotAvailableColor: #d1d5db8c;

.locationUnselected {
  fill: $locationUnselectedColor;
  //   cursor: pointer;

  &:focus,
  &:hover {
    // fill: #f89f44;
    outline: 0;
  }

  //   &[aria-checked="true"] {
  //     fill: #f89f44
  //   }
}

.locationSelected {
  fill: $locationSelectedColor;
  &:focus,
  &:hover {
    outline: 0;
  }
}

.locationNotAvailable {
  fill: $locationNotAvailableColor;
  &:focus,
  &:hover {
    outline: 0;
  }
}

.svg-custom-map {
  width: 100%;
  height: 100%;
  stroke: #fff;
  stroke-width: 1;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.squareAvailable {
  height: 10px;
  width: 10px;
  background-color: $locationUnselectedColor;
}
.squareNotAvailable {
  @extend .squareAvailable;
  background-color: $locationNotAvailableColor;
}
.squareSelected {
  @extend .squareAvailable;
  background-color: $locationSelectedColor;
}
// Map CSS END

.infoText {
  color: #aaa49f;
  font-size: 0.95rem;
}

.smallText {
  font-size: 0.85rem;
}

.country_list_wrapper {
  border: 1px solid #ededed;
  border-radius: 4px;
  height: 300px;
  overflow-y: auto;
}

.country_list_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  &:hover {
    background-color: #e6e8eb7d;
  }
}

.test_summary {
  padding: 2rem !important;
  padding-top: 0px !important;
}
.test_summary .avatar .avatar-content {
  width: 48px;
  height: 48px;
}
.summaryItem {
  padding-top: 10px;
  padding-bottom: 10px;
}

.reset-zoom {
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.5rem;
  font-size: 1rem;
}

.dashboard-card {
  cursor: pointer;
}

.statSubTextColor {
  color: $orange;
  font-size: 0.875rem;
  font-weight: 500;
  font-style: normal;
}

.editable:hover > .hidden {
  display: block;
  visibility: visible;
  cursor: pointer;
}

// DEBUG MODAL CSS //
$debugModalBorderColor: #bec4d38c;
.scenarioStepbar {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  border-right: 1px solid $debugModalBorderColor;
  height: 45rem;
  overflow-y: auto;

  .nav-link.active:after {
    left: -6px !important;
    right: auto !important;
  }

  .nav-link {
    justify-content: space-between !important;
    display: flex !important;
    padding-right: 0 !important;
    padding-left: 1rem !important;
    font-weight: 400;
  }
}

.bottomBorder {
  border-bottom: 1px solid $debugModalBorderColor;
  padding-bottom: 1.5rem;
}

.debugModalSidebarStepName {
  max-width: 12rem;
  text-overflow: ellipsis;
  overflow: hidden;
}

.debugCard {
  box-shadow: none !important;
  transition: none !important;
  padding: 1rem;
  margin-bottom: 1rem;
}

.debugModalMinWidth {
  min-width: 80%;
}

.debugModalMinWidth {
  height: 50rem;
}

.debugContent {
  height: 40rem;
  overflow: auto;
  max-width: 90rem;
}

.serviceMapDetailsModalContent {
  height: 40rem;
  overflow: auto;
}

.monitorDashboard {
  width: calc(100%);
}

.service-map-spinner.loading.component-loader {
  margin-top: 0px !important;
}

.debugHeaderTableValue {
  max-width: 50rem;
}

.debugContentPane {
  margin-right: 1rem;
}

.debugMethodBadge {
  text-align: center;
  font-size: 1rem;
  padding-top: 0.8rem;
  margin-right: 1rem;
}

.debugURL {
  opacity: 1 !important;
}

.debugBodyCopyButton {
  margin-bottom: -7rem;
  z-index: 1;
  margin-right: 2rem;
}
// DEBUG MODAL CSS END //

.postmanFolderTree {
  .custom-checkbox .custom-control-label::before,
  .custom-checkbox .custom-control-label::after,
  .custom-radio .custom-control-label::before,
  .custom-radio .custom-control-label::after {
    width: 14px;
    height: 14px;
    margin-top: 0.2rem;
  }
}

.postman-import.vertical-wizard {
  .bs-stepper {
    box-shadow: none !important;
  }
}
.postman-import.vertical-wizard {
  margin-bottom: 0 !important;
}

.postmanFolderTree {
  max-height: 23rem;
  overflow-y: auto;
}

// Environments
.noEnvContent {
  padding: 3rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.environmentCard {
  height: 45rem;
}

.environmentSidebar {
  border-right: 1px solid;
  border-color: #ebe9f1;

  .nav-vertical {
    width: 100%;
    height: 40rem;
    overflow-y: auto;

    .nav-link {
      padding-left: 1rem !important;
    }
    .nav-link.active:after {
      left: -4px !important;
      right: auto !important;
      transition: none;
    }
  }
}

.newEnvButton {
  height: 5rem;
  display: flex;
  align-items: center;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border-bottom: 1px solid #ebe9f1;
}

.envContentHeader {
  height: 5rem;
  display: flex;
  align-content: center;
  padding-left: 1rem;
  border-bottom: 1px solid #ebe9f1;
}

.envContent {
  height: 45rem;

  .tab-pane {
    height: 40rem;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 1rem;
  }
}

#environment-select-box {
  min-width: 12rem;
}

#testdata-select-box {
  min-width: 12rem;
}

.previewDataHeaderType {
  margin-bottom: 0;
  .select__control {
    background-color: #e9e8e6;
  }
}

.addEngineCopyButton {
  position: relative;
  top: 2.6rem;
  z-index: 100;
  left: 27rem;
}
.addClusterCopyButton {
  position: relative;
  top: 2.6rem;
  z-index: 100;
  left: 46rem;
}

.modalDetailsSqlCopyButton {
  position: absolute;
  top: 0.5rem;
  z-index: 100;
  right: 50.5%;
}

.csvCell {
  height: 2rem !important;

  .cellText {
    width: 12rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
}

.csvPreviewTable {
  overflow-x: auto;
  padding-bottom: 1rem;
}

.csvPreviewTableColumn {
  .thFields {
    padding: 0 0.5rem;
  }
}

.filter-tooltip .tooltip {
  pointer-events: all;
  .tooltip-inner {
    min-width: 260px;
    max-width: 600px;
    background-color: $light;
    color: #5b6070;
    box-shadow: 2px 2px 1px $primary;
    border-top: 0.2px solid $primary;
    border-left: 0.2px solid $primary;
  }
}
.edgeLabelRendererContainer .tooltip {
  pointer-events: all;
  .tooltip-inner {
    max-width: 600px;
    background-color: $theme-dark-card-bg;
    box-shadow: 2px 2px 1px darkcyan;
    border-top: 0.2px solid darkcyan;
    border-left: 0.2px solid darkcyan;
  }
}

// Environments CSS END

// Put all your CSS in here for dark-theme
.dark-layout {
  $locationUnselectedColor: #a7b5dd;
  $locationNotAvailableColor: #a7b5ddb3;
  $debugModalBorderColor: #7576788c;
  $passiveReactDatTableTextColor: #5b6070;

  .disabledtextcolor {
    color: $passiveReactDatTableTextColor;
  }

  .previewDataHeaderType {
    .select__control {
      background-color: #2f384e;
    }
  }

  .envContentHeader {
    border-color: $theme-dark-border-color;
  }
  .newEnvButton {
    border-color: $theme-dark-border-color;
  }
  .environmentSidebar {
    border-color: $theme-dark-border-color;
  }

  .select__placeholder {
    color: #626a7a !important;
  }
  .stepDetailTitle {
    color: #676d7d;
  }
  .stepDetailsTitleWithoutAlignment {
    color: #676d7d;
  }
  .locationUnselected {
    fill: $locationUnselectedColor;
  }
  .locationNotAvailable {
    fill: $locationNotAvailableColor;
  }
  .svg-custom-map {
    stroke: #28304785;
  }
  .squareAvailable {
    background-color: $locationUnselectedColor;
  }
  .squareNotAvailable {
    background-color: $locationNotAvailableColor;
  }
  .squareSelected {
    background-color: $locationSelectedColor;
  }

  .fileButtonMultipart {
    border-color: #404656 !important;
  }

  .fileContentMultipart {
    border: 1px solid #404656 !important;
  }

  .fileTextMultipart {
    border: 1px solid #444a5a;
  }

  .country_list_wrapper {
    border-color: #3b4253;
  }

  .country_list_item {
    &:hover {
      background-color: #3d4660;
    }
  }

  .saveAsTestPlan {
    border-color: #51515187;
  }

  .statSubTextColor {
    color: #b3b6bc;
  }

  .scenarioStepbar {
    border-right: 1px solid $debugModalBorderColor;
  }

  .bottomBorder {
    border-bottom: 1px solid $debugModalBorderColor;
  }

  input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    border-radius: 4px;
    margin-right: 2px;
    opacity: 0.6;
    filter: invert(0.8);
  }

  input[type="datetime-local"]::-webkit-calendar-picker-indicator:hover {
    opacity: 1;
  }

  .filter-tooltip .tooltip {
    pointer-events: all;
    .tooltip-inner {
      max-width: 600px;
      color: $theme-dark-body-color;
      background-color: $theme-dark-card-bg;
      box-shadow: 2px 2px 1px darkcyan;
      border-top: 0.2px solid darkcyan;
      border-left: 0.2px solid darkcyan;
    }
  }

  .edgeLabelRendererContainer .tooltip {
    pointer-events: all;
    .tooltip-inner {
      max-width: 600px;
      background-color: $theme-dark-card-bg;
      box-shadow: 2px 2px 1px darkcyan;
      border-top: 0.2px solid darkcyan;
      border-left: 0.2px solid darkcyan;
    }
  }
}

.rti--container {
  --rti-bg: $theme-dark-body-color !important;
  --rti-border: #3b4253 !important;
  --rti-tag-remove: #0ab89b !important;
  --rti-main: #3182ce !important;
  --rti-radius: 0.375rem !important;
  --rti-s: 0.5rem !important; /* spacing */
  --rti-tag: rgba(247, 167, 28, 0.12) !important;
  color: #0ab89b !important;
  .rti--tag > button {
    font-size: 0.8rem !important;
    font-weight: bolder !important;
    color: #0ab89b !important;
    border: none !important;
    outline: none !important;
  }
  .rti--input {
    background: transparent !important;
    color: #b4b7bd !important;
  }
}
// dark-theme CSS END

//
// Datatable CSS
//

a:not([href]),
.dataTableLink {
  color: inherit;
  cursor: pointer;

  @include hover() {
    color: $primary;
  }
}

// Datatable CSS END

.blur {
  -webkit-animation: blur 0.5s linear forwards;
  -moz-animation: blur 0.5s linear forwards;
  -ms-animation: blur 0.5s linear forwards;
  -o-animation: blur 0.5s linear forwards;
  animation: blur 0.5s linear forwards;
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}
.delay {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}
@-webkit-keyframes blur {
  to {
    -webkit-filter: blur(5px);
    filter: blur(5px);
  }
}
@-moz-keyframes blur {
  to {
    -moz-filter: blur(5px);
    filter: blur(5px);
  }
}
@keyframes blur {
  to {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    filter: blur(5px);
  }
}

.controls {
  display: flex;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.controls > button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 2rem;
  width: 2rem;
  background: #f89f44;
  color: #fff;
  border-radius: 100%;
  border: 0;
}
.controls > button:first-child {
  margin-right: 0.25rem;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
  background-color: transparent !important;
}

// postman import css
.uppy-DragDrop--isDragDropSupported {
  background-color: transparent !important;
}

.uppy-DragDrop-inner {
  height: "100%" !important;
}

.stepMethodBadge {
  text-align: center;
  font-size: 1rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  margin-right: 1rem;
}

.selectRequestBadge {
  text-align: center;
  font-size: 0.9rem;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  margin-right: 1rem;
}

.fsSmall {
  font-size: 0.9rem !important;
}

.postmanImportSelectTitle {
  font-size: 1.1rem;
}

.clearRightLeftPadding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.importPostmanModal {
  max-width: 90rem;
}

.postmanInfoCard {
  height: 18rem;
  width: 80%;
  box-shadow: -9px -4px 17px 5px rgba(176, 176, 176, 0.76);
}

.successIconPostman {
  background-color: transparent;
  color: #28c76f;
  margin-right: 0.3rem;
}

.overflowTextElipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.postmanInfoTitle {
  font-weight: 600;
}

.postmanInfoDesc {
  margin-left: 1.5rem;
}

.rightBordered {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid $input-border-color;
  border-left: none;
}
.unitPart {
  width: 36px;
  padding: 0 4px;
  border-left: 1px solid $input-border-color;
  text-align: center;
  line-height: 37px;
  max-height: 38px;
}

.arrayPartRight {
  text-align: center;
  line-height: 37px;
  width: 18px;
  max-height: 38px;
}

.rangeSeperator {
  padding: 0 8px;
  line-height: 37px;
}

.arrayPartLeft {
  border: 1px solid $input-border-color;
  border-right: none;
  text-align: center;
  line-height: 37px;
  width: 18px;
  max-height: 38px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.dark-layout {
  .tooltip-inner-assertion {
    background-color: $theme-dark-card-bg;
    border: 0.1px #fff solid;
    pointer-events: auto;
  }
}

.tooltip-inner-assertion {
  background-color: $primary;
  border: 0.1px #000 solid;
  pointer-events: auto;
}
.cell {
  border: $secondary 1px solid;
  border-bottom: none;
  border-right: none;
  padding: 8px 0;
  text-align: center;
  width: 100%;
  justify-content: center;
  margin: 0;
}

.pricingWrapper {
  min-width: 1000px;
  overflow: auto;
}

.selfhosted-row {
  padding-bottom: 9.05px;
}

.selfhosted-top-cell {
  padding-top: 6.5px !important;
  padding-bottom: 9.05px;
  padding-left: 1rem;
}

.sso-cell {
  padding-top: 8.3px !important;
}

.selfhosted-cell {
  border: $secondary 1px solid;
  border-bottom: none;
  border-right: none;
  padding: 8px 0;
  text-align: left;
  width: 100%;
  justify-content: flex-start;
  margin: 0;
}

.team-plan-cell {
  padding-bottom: 10px;
}

.top-cell {
  border-top: none;
  border-bottom: none;
}

.buffer-cell {
  font-size: 1.5rem;
  min-height: 64px;
  justify-content: flex-start;
  align-items: flex-end;
}

.buffer-cell-md {
  font-size: 1.5rem;
  min-height: 37px;
  justify-content: flex-start;
  align-items: flex-end;
}

.bottom-cell {
  border-bottom: none;
  height: 64px;
}

.plan-name {
  height: 96px;
  border: none;
  font-size: 20px;
  font-weight: 400;
}

.month-text {
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 20px;
  padding-left: 3px;
  color: $secondary;
}

.price-integer-part-line,
.price-decimal-part-line {
  text-decoration: line-through;
}

.selected-plan {
  margin-top: -1px;
  border: $primary 1px solid;
  border-radius: 16px;
}

.single-plan:hover {
  color: $primary;
}

.back-drop {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 200vh;
  z-index: 999;
  background-color: rgb(0, 0, 0, 0.2);
  pointer-events: none;
}

.popular-badge {
  font-size: 8px;
  margin-left: 8px;
  padding-bottom: 3px;
  vertical-align: 8px;
}

.height-150px {
  height: 150px;
}

.link-hover-primary {
  &:hover {
    color: $primary !important;
  }
}

.dark-layout {
  .link-hover-primary {
    &:hover {
      color: $white !important;
    }
  }
}

.disable-tooltip-button {
  cursor: default !important;
}

.disable-tooltip-button:hover {
  pointer-events: default;
}

.disable-tooltip-button:active {
  background-color: transparent !important;
}

.disable-tooltip-button:focus {
  background-color: transparent !important;
}

.alert-limiter {
  max-height: 185px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.alert-limiter::-webkit-scrollbar-track {
  display: none;
}

.br1 {
  border-radius: 2px;
}
.br2 {
  border-radius: 4px;
}
.br3 {
  border-radius: 8px;
}
.br4 {
  border-radius: 16px;
}

.ai-analyzer-card {
  background-size: cover;
  height: 400px;
  width: 677px;
  opacity: 0.85;
  transition: opacity 1s ease;
  border-radius: 16px;
  .ai-analyzer-text {
    background: rgba(16, 76, 83, 0.8) !important;
    transition: background-color 1s ease;
    position: absolute;
    bottom: 48px;
    margin: 16px;
    max-width: 650px;
  }
  .ai-analyzer-docs-redirection {
    // warning rgba with yellow, orange color rgba(255, 165, 0, 0.8)

    background: rgba(16, 76, 83, 0.8) !important;
    transition: background-color 1s ease;
    position: absolute;
    bottom: 1px;
    margin: 6px;
    max-width: 650px;
  }
}

.ai-analyzer-card:hover {
  opacity: 1;
  .ai-analyzer-text {
    background: rgba(16, 76, 83, 0.85) !important;
  }
}

#cool-loader {
  overflow: hidden;
  background: rgba(30, 30, 30, 0.7);
  color: rgba(255, 255, 255, 0.8);
  width: 150px;
  padding-bottom: 10px;
  .react-spinner-loader-svg {
    position: relative;
    margin: 0 auto;
    width: 128px;
    margin-top: 20%;
    text-align: center;
  }

  .react-spinner-loader-svg svg {
    -webkit-transform-origin: 50% 65%;
    transform-origin: 50% 65%;
  }

  .react-spinner-loader-svg svg polygon {
    stroke-dasharray: 17;
    -webkit-animation: dash 2.5s cubic-bezier(0.35, 0.04, 0.63, 0.95) infinite;
    animation: dash 2.5s cubic-bezier(0.35, 0.04, 0.63, 0.95) infinite;
  }

  @-webkit-keyframes dash {
    to {
      stroke-dashoffset: 136;
    }
  }

  @keyframes dash {
    to {
      stroke-dashoffset: 136;
    }
  }
  @-webkit-keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}

.cm-tooltip {
  z-index: 1000000 !important;
  position: sticky !important;
  background-color: inherit !important;
  width: 100% !important;
}

.cm-tooltip-autocomplete ul::-webkit-scrollbar-track {
  display: none !important;
}

.cm-editor {
  min-height: 70px !important;
}

.cm-completionInfo {
  width: 100% !important;
  left: 0px !important;
}

.vertical-divider {
  height: 30px;
  border-left: 1px solid $theme-dark-border-color;
  width: 1px;
}

.edge-color-legend {
  width: calc(100% - 38px);
  margin: 0 0 4px 19px;
  height: 12px;
  border-radius: 12px;
  background: rgb(0, 255, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 255, 0, 1) 0%,
    rgba(255, 0, 0, 1) 100%
  );
}

.edge-thickness-legend {
  margin-left: 20px;
  border-right: $input-border-color solid 260px;
  border-top: solid transparent 20px;
  border-bottom: solid transparent 20px;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-70 {
  opacity: 0.7 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.opacity-10 {
  opacity: 0.1 !important;
}

.opacity-0 {
  opacity: 0 !important;
}
.opacity-1 {
  opacity: 1 !important;
}

.hoverOnly:hover {
  pointer-events: all;
}

.hoverOnly {
  pointer-events: all;
}

.transition-3 {
  transition: opacity 0.4s !important;
}

.size-500 {
  height: 500px;
  width: 500px;
}

.vertical-divider {
  height: 30px;
  border-left: 1px solid $theme-dark-border-color;
  width: 1px;
}

.dark-layout {
  .hover-arrow:hover {
    background-color: #40465677;
  }

  .hover-arrow {
    cursor: pointer;
    margin-left: 2px;
    color: $white;
  }
}

.hover-arrow:hover {
  background-color: #aeadad;
}

.hover-arrow {
  cursor: pointer;
  margin-left: 2px;
  color: $secondary;
}

.clusterAddHighlighter .language-bash {
  padding-left: 0px;
}

/* BoxComponent.scss */
.box-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: gray;
  margin: 10px;
  color: white;
  font-size: 18px;
  border: 1px solid #ccc;
  transition: all 0.3s ease;

  &:hover {
    background-color: darkgray;
  }
}

// keepTooltipOpenOnHover
.keepTooltipOpenOnHover {
  max-width: 30%;
  min-width: 10%;
  font-size: 10px !important;
  pointer-events: auto !important;
  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }
}

.method-select-tooltip .tooltip {
  pointer-events: all;
  .tooltip-inner {
    background-color: $light;
    color: #5b6070;
    box-shadow: 2px 2px 1px $primary;
    border-top: 0.2px solid $primary;
    border-left: 0.2px solid $primary;
  }
}
.anteon-custom-gradient {
  background: linear-gradient(
    118deg,
    $primary,
    rgba($color: $primary, $alpha: 0.32)
  );
  height: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.loading-bar {
  width: 190px;
  height: 20px;
  border: none;
}

.trafficProtocolContainer {
  width: 100&;
  cursor: pointer;
  // when cursor hover this class element, show bg color $secondary with opacity and animation
  &:hover {
    background-color: rgba($primary, 0.05);
    animation: 0.5s ease-out-in 0s normal none 1 running fadeOut;
    // add border radius
    border-radius: 5px;
  }
}

.border-radius-left {
  border-radius: 8px 0px 0px 8px;
}

.border-radius-left-top {
  border-radius: 8px 0px 0px 0px;
}

.border-radius-left-bottom {
  border-radius: 0px 0px 0px 8px;
}

.border-radius-right {
  border-radius: 0px 8px 8px 0px;
}

.border-radius-right-top {
  border-radius: 0px 8px 0px 0px;
}

.border-radius-right-bottom {
  border-radius: 0px 0px 8px 0px;
}

.minHeight150 {
  min-height: 150px;
}
.dark-layout {
  .primeToDarkGradient {
    background: rgb(247, 167, 28);
    background: -moz-linear-gradient(
      90deg,
      rgba(247, 167, 28, 1) 4%,
      rgba(40, 48, 70, 1) 96%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(247, 167, 28, 1) 4%,
      rgba(40, 48, 70, 1) 96%
    );
    background: linear-gradient(
      90deg,
      rgba(247, 167, 28, 1) 4%,
      rgba(40, 48, 70, 1) 96%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0ab89b",endColorstr="#283046",GradientType=1);
  }
}

.primeToDarkGradient {
  background: rgb(247, 167, 28);
  background: -moz-linear-gradient(
    90deg,
    rgba(247, 167, 28, 1) 4%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(247, 167, 28, 1) 4%,
    rgb(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(247, 167, 28, 1) 4%,
    rgb(255, 255, 255) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0ab89b",endColorstr="#ffffff",GradientType=1);
}

.hr-primary {
  border-color: $debugModalBorderColor !important;
}

.broken-hr {
  display: flex;
  flex-direction: row;
  color: $primary;
}
.broken-hr:before,
.broken-hr:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid;
  margin: auto;

  border-image: linear-gradient(
    to right,
    $debugModalBorderColor,
    $debugModalBorderColor
  );

  border-image-slice: 0.5;
}
.broken-hr:before {
  margin-right: 10px;
}
.broken-hr:after {
  margin-left: 10px;
}

.horizontal-line {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #000;
  line-height: 0.1em;
  margin-left: auto;
  margin-right: auto;
}

.border-top-primary {
  border-top: 1px solid $primary;
  background-color: inherit;
}

.customGanttTooltip {
  color: $black-600;
  background-color: $card-bg;
  box-shadow: 2px 2px 1px darkcyan;
  border-top: 0.2px solid darkcyan;
  border-left: 0.2px solid darkcyan;
}

.pf-m-match {
  background-color: $primary !important;
  opacity: 0.6 !important;
  color: $white !important;
}

.pf-m-current {
  background-color: $primary !important;
  color: $white !important;
}

.pf-v5-c-log-viewer__list-item {
  font-family: var(
    --fontStack-monospace,
    ui-monospace,
    SFMono-Regular,
    SF Mono,
    Menlo,
    Consolas,
    Liberation Mono,
    monospace
  );

  font-weight: 400;
  text-size-adjust: 100%;

  color: $secondary !important;
  &:hover {
    color: $theme-dark-card-bg !important;
  }
}

.dark-layout {
  .pf-v5-c-log-viewer__list-item {
    color: $white !important;
    &:hover {
      color: $primary !important;
    }
  }
}

.anteon-focus:focus-visible {
  border: 1px $primary solid !important;
  outline: none !important;
}

.tw-scrollbox {
  -webkit-box-shadow: inset 0px -61px 68px -88px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: inset 0px -61px 68px -88px rgba(0, 0, 0, 0.8);
  box-shadow: inset 0px -61px 68px -88px rgba(0, 0, 0, 0.8);
}

/* Hide the scrollbar track for Chrome, Safari, and Opera */
.remove-scroll-track::-webkit-scrollbar-track {
  display: none !important; /* Hide the track by setting its background to none */
}

.remove-scroll-track::-webkit-scrollbar {
  overscroll-behavior: contain !important;
  width: 5px !important;
  height: 10px !important;
}

.remove-scroll-track::-webkit-scrollbar-thumb {
  overscroll-behavior: contain !important;
  width: 5px !important;
  background: rgba($color: $primary, $alpha: 0.64) !important;
  border-radius: 10px !important;
  margin-left: 3px !important;
  cursor: pointer !important;
  margin-bottom: 20px;
}

.remove-scroll-track::-webkit-scrollbar-thumb:hover {
  background: rgba($color: $primary, $alpha: 1) !important;
}

.direction-ltr {
  direction: ltr !important;
}

.direction-rtl {
  direction: rtl !important;
}

.give-box-shadow {
  box-shadow: 0 4px 24px 0 rgba($black, 0.1);
}
