.load-test-runs {
  .container-load-test-runs {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    font-weight: 500;
  }

  @media (max-width: 991px) {
    .container-load-test-runs {
      padding: 0 20px;
    }
  }

  .header {
    display: flex;
    gap: 16px;
  }

  @media (max-width: 991px) {
    .header {
      flex-wrap: wrap;
    }
  }

  .title {
    color: $black-600;
    flex: 1;
    font: 20px Inter, sans-serif;
  }

  @media (max-width: 991px) {
    .title {
      max-width: 100%;
    }
  }

  .listAllWrapper {
    display: flex;
    gap: 4px;
    font-size: 14px;
    color: $primary;
    margin: auto 0;
  }

  .listAllText {
    font-family: Inter, sans-serif;
  }

  .listAllIcon {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 16px;
    align-self: start;
  }

  .tableWrapper {
    border-radius: 12px;
    border: 1px solid $card-border-bg;
    background-color: $card-bg;
    display: flex;
    margin-top: 16px;
    padding-bottom: 1rem;
    flex-direction: column;
  }

  @media (max-width: 991px) {
    .tableWrapper {
      max-width: 100%;
    }
  }

  .tableHeader {
    display: flex;
    font-size: 12px;
    color: $body-color;
    padding: 12px 18px;
  }

  .headerCell {
    flex: 1;
    font-family: Inter, sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .tableBody {
    border-top: 1px solid $card-border-bg;
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 991px) {
    .tableBody {
      max-width: 100%;
    }
  }

  .tableRow {
    border-bottom: 1px solid $card-border-bg;
    display: flex;
    width: 100%;
    padding: 14px 18px;
  }

  .tableRow:nth-child(even) {
    background-color: $body-bg;
  }

  .tableRow:nth-child(odd) {
    background-color: #fff;
  }

  @media (max-width: 991px) {
    .tableRow {
      max-width: 100%;
      flex-wrap: wrap;
    }
  }

  .cellContent {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .testName {
    color: $black-600;
    font: 12px Inter, sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .statusBadge {
    border-radius: 100px;
    border: 1px solid;
    padding: 4px 8px;
    font: 10px Inter, sans-serif;
    white-space: nowrap;
    text-align: center;
  }

  //   .statusRunning {
  //     border-color: rgba(220, 38, 38, 1);
  //     background-color: var(--dier-renkler-error-50, #fef2f2);
  //     color: var(--Dier-Renkler-Error, #dc2626);
  //   }

  //   .statusDone {
  //     border-color: rgba(22, 163, 74, 1);
  //     background-color: var(--dier-renkler-success-50, #f0fdf4);
  //     color: var(--Dier-Renkler-Success, #16a34a);
  //   }

  @media (max-width: 991px) {
    .statusBadge {
      white-space: normal;
    }
  }

  .infoCell {
    font-size: 12px;
    color: $black-600;
    font-family: Inter, sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .infoIcon {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 16px;
    margin-left: auto;
  }

  .tableFooter {
    border-bottom: 1px solid $card-border-bg;
    background-color: #fff;
    margin-bottom: 16px;
    height: 48px;
  }

  @media (max-width: 991px) {
    .tableFooter {
      max-width: 100%;
    }
  }
}
