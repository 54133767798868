.card {
  border: 1px solid $card-border-bg;
  margin-bottom: $content-padding;
  // box-shadow: $box-shadow;
  background-color: $card-bg;
  transition: all 0.3s ease-in-out, background 0s, color 0s, border-color 0s;
  .card {
    box-shadow: none !important;
  }
  .card-title {
    font-weight: 500;
    font-size: 0.875rem;
    margin-bottom: 1.53rem; // Considering sub-title minus margin
    line-height: normal;
    color: $black-600;
  }
  .card-bordered {
    border: $card-border-width solid $card-border-color;
  }
  .card-img {
    object-fit: cover;
  }
  .card-img-overlay {
    border-radius: $card-border-radius;
  }

  //fullscreen card
  &.card-fullscreen {
    display: block;
    z-index: 9999;
    position: fixed;
    width: 100% !important;
    height: 100% !important;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    overflow: auto;
  }
  .card-body[class*="border-bottom-"] {
    border-bottom-width: 2px !important;
  }
  .card-img-overlay {
    &.bg-overlay {
      background: rgba($black, 0.45);
    }
    .text-muted {
      color: $gray-800 !important;
    }
  }
  &.card-minimal {
    border: none;
    box-shadow: none;
  }

  .card-header {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: none;
    padding: 1.25rem;
    background-color: transparent;
    .card-title {
      margin-bottom: 0;
    }

    .heading-elements {
      position: relative;
      top: -1px;

      li:not(:first-child) a {
        margin-left: 0.75rem;
      }

      a {
        &.btn {
          padding-top: 6px;
          padding-bottom: 6px;
        }
        i,
        svg {
          height: 1rem;
          width: 1rem;
          font-size: 1rem;
        }
        &[data-action="collapse"] {
          i,
          svg {
            transition: all 0.25s ease-out;
            display: inline-block;
          }
          &.rotate {
            i,
            svg {
              transform: rotate(-180deg);
            }
          }
        }
      }
    }

    & + .card-content > .card-body:first-of-type,
    & + .card-body {
      padding-top: 0px;
    }
  }
  .card-footer {
    border-top: 1px solid $gray-300;
    background-color: transparent;
  }

  // Card Legend
  .card-legend {
    // flex row style
    &.card-legend {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .card-legend-legend-item {
        &:not(:first-child) {
          margin-left: 15px;
        }
        display: flex;
        align-items: center;
        justify-content: space-between;

        .card-legend-label {
          margin-left: 0.3rem;
        }
      }
    }
    .card-legend-color-icon {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
  }

  .filterCard {
    .filterCard__parentCategory {
      margin-bottom: 10px;

      label {
        margin-left: 0px;
      }
      input[type="checkbox"] {
        margin-right: 10px;
      }
      .custom-input-label {
        margin-left: 24px;
      }
    }

    .filterCard__subCategory {
      margin-bottom: 5px;
      // display: flex;
      // align-items: center;

      label {
        margin-left: 10px;
        // input[type="checkbox"] {
        //   margin-right: 0px !important;
        // }
      }
      .custom-input-label {
        margin-left: 15px;
      }
    }
    .filterCard__subsubCategory {
      margin-bottom: 5px;
      // display: flex;
      // align-items: center;

      label {
        margin-left: 25px;
        // input[type="checkbox"] {
        //   margin-right: 0px;
        // }
      }
      .custom-input-label {
        margin-left: 5px;
      }
    }
  }
}
// Card Column
.card-columns {
  .card {
    margin-bottom: $spacer * 2.2;
  }
}

.card-group,
.card-deck {
  margin-bottom: 0.75rem;
}

.card-head-inverse {
  .heading-elements {
    i,
    svg {
      color: $white;
    }
  }
  color: $white;
}
.card-transparent {
  background-color: transparent;
}

.text-white {
  .card-img-overlay {
    .text-muted {
      color: $white !important;
    }
  }
  code {
    background-color: rgba($white, 1);
  }
  .heading-elements {
    i,
    svg {
      color: $white;
    }
  }
}

// overlay-img-card
.overlay-img-card {
  .card-img-overlay,
  img {
    max-height: 34.64rem;
  }
}

// IE Specific CSS
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  // img-fluid class
  .card-body,
  .card-content {
    min-height: 1px;
  }
}

//--------------- Advance Cards ---------------
//---------------------------------------------

// Congratulations Card
.card-congratulations {
  background: linear-gradient(118deg, rgba($primary, 1), rgba($primary, 0.7));
  color: $white;

  // image on left
  .congratulations-img-left {
    width: 200px;
    position: absolute;
    top: 0;
    left: 0;

    @include media-breakpoint-down(xs) {
      width: 140px;
    }
  }
  // image on right
  .congratulations-img-right {
    width: 175px;
    position: absolute;
    top: 0;
    right: 0;

    @include media-breakpoint-down(xs) {
      width: 140px;
    }
  }

  .avatar {
    margin-bottom: 2rem;
  }
}
// congratulation medal card
.congratulation-medal {
  position: absolute;
  top: 0;
  right: 37px;
}

// Deposits Card
.card-deposits {
  .deposits-divider {
    margin-top: 1.8rem;
    margin-bottom: 1.4rem;
  }
}

// Employee Task Card
.card-employee-task {
  .employee-task {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
}

// Developer Meetup Card
.card-developer-meetup {
  .meetup-img-wrapper {
    background-color: rgba($primary, 0.1);
  }
  .meetup-header {
    margin-bottom: 2rem;

    .meetup-day {
      text-align: center;
      border-right: 1px solid $custom-control-border-color;
      padding-right: 1.3rem;
      margin-right: 1.3rem;
    }
  }
  .media {
    .avatar .avatar-content {
      width: 34px;
      height: 34px;
    }
    & + .media {
      margin-top: 1.2rem;
    }
  }
  .avatar-group {
    margin-top: 2rem;
  }
}

// Profile Card
.card-profile {
  text-align: center;
  .card-body {
    position: relative;
    padding: $card-spacer-y;

    .profile-image-wrapper {
      display: flex;
      justify-content: center;

      .profile-image {
        position: absolute;
        top: -4rem;
        padding: 0.5rem;
        border-radius: 50%;
        background-color: $white;
        box-shadow: 0 0 8px 0 rgba($black, 0.14);
        margin-bottom: 1.15rem;

        img {
          width: 100px;
          height: 100px;
        }
      }
    }

    .profile-badge {
      margin-top: 0.8rem;
      margin-bottom: 0.8rem;
    }
  }
}

// Apply Job Card
.card-apply-job {
  .apply-job-package {
    padding: 1.2rem;
    margin-bottom: 1.15rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    sup {
      top: -0.8rem;
    }
  }
}

// Transaction Card
.card-transaction {
  // only first container has border-radius top
  .card-body {
    padding: 0.25rem;
  }
  .container {
    &:first-child {
      border-radius: 0.5rem 0.5rem 0 0;
    }
    &:last-child {
      border-radius: 0 0 0.5rem 0.5rem;
    }

    justify-content: center;
    align-self: stretch;

    border: 1px solid $card-border-bg;
    background-color: #fff;
    display: flex;
    gap: 10px;
    padding: 16px;
  }

  .infoWrapper {
    justify-content: center;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .label {
    color: $body-color;
    font: 500 12px Inter, sans-serif;
  }

  .value {
    color: $black-600;
    margin-top: 6px;
    font: 600 16px Inter, sans-serif;
  }

  .iconWrapper {
    align-items: center;
    border-radius: 8px;
    box-shadow: 2px 2px 10px 0 rgba(247, 167, 28, 0.1) inset,
      2px 2px 10px 0 rgba(247, 167, 28, 0.1), 0 0 2px 0 $orange;
    background-color: #fff;
    display: flex;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 8px;
  }

  .icon {
    aspect-ratio: 1;
    object-fit: contain;
    width: 24px;
  }

  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .statisticsContainer {
    align-self: stretch;
    width: 100%;
  }
  .statisticsContainer {
    .statisticsGrid {
      gap: 0.12rem;
      display: flex;
    }

    @media (max-width: 991px) {
      .statisticsGrid {
        flex-direction: column;
        align-items: stretch;
        gap: 0;
      }
    }

    .statisticsColumn {
      display: flex;
      flex-direction: column;
      line-height: normal;
      width: 33%;
      margin-left: 0;
    }

    @media (max-width: 991px) {
      .statisticsColumn {
        width: 100%;
      }
    }

    .statisticsCard {
      display: flex;
      flex-direction: column;
      gap: 0.12rem;
    }

    .cardContent {
      justify-content: center;
      border: 1px solid $card-border-bg;
      background-color: #fff;
      display: flex;
      gap: 10px;
      padding: 16px;
    }

    .cardInfo {
      justify-content: center;
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    .cardLabel {
      color: $body-color;
      font: 500 12px Inter, sans-serif;
    }

    .cardValue {
      color: $black-600;
      margin-top: 6px;
      font: 600 16px Inter, sans-serif;
    }

    .iconWrapper {
      align-items: center;
      border-radius: 8px;
      box-shadow: 2px 2px 10px 0 rgba(247, 167, 28, 0.1) inset,
        2px 2px 10px 0 rgba(247, 167, 28, 0.1), 0 0 2px 0 #f7a71c;
      border: 0 solid rgba(247, 167, 28, 0.1);
      background-color: #fff;
      display: flex;
      justify-content: center;
      width: 40px;
      height: 40px;
      padding: 8px;
    }

    .icon {
      aspect-ratio: 1;
      object-fit: auto;
      object-position: center;
      width: 24px;
    }

    .topLeftCard {
      border-radius: 8px 0 0 0;
    }

    .bottomLeftCard {
      border-radius: 0 0 0 8px;
    }

    .topRightCard {
      border-radius: 0 8px 0 0;
    }

    .bottomRightCard {
      border-radius: 0 0 8px 0;
    }
  }
}
//dashboard components
.dashboard-results-card {
  height: calc(100% - 28px);
  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%;
    margin: 0 20px;

    .list-all-button {
      font-size: 16px;
      line-height: 22px;
    }
  }
  width: 100%;
  .dashboard-table-header {
    font-size: 14px;
    margin: 12px 20px;
  }
  .dashboard-table-item {
    &:hover {
      background-color: #ddddde;
    }
    background-color: $light;
    color: $black;
    margin: 12px;
    padding: 8px;
    border-radius: 8px;
  }
}
.dark-layout {
  .dashboard-results-card {
    height: calc(100% - 28px);
    .title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: nowrap;
      width: 100%;
      margin: 0 20px;

      .list-all-button {
        font-size: 16px;
        line-height: 22px;
      }

      .engine-button {
        font-size: 16px;
        line-height: 16px;
      }
      .engine-button-disabled {
        opacity: 0.65;
        color: #0ab89b;
        background-color: transparent;
        border: 1px solid #0ab89b !important;
        &:hover,
        &:focus,
        &:focus-visible {
          background-color: transparent;
        }
      }
    }
    width: 100%;
    .dashboard-table-header {
      font-size: 14px;
      margin: 12px 20px;
    }
    .dashboard-table-item {
      &:hover {
        background-color: #12192d !important;
      }
      background-color: #12192d4a !important;
      color: #b4b7bd !important;
      margin: 12px;
      padding: 8px;
      border-radius: 8px;
    }
  }
}
.dashboard-skeleton {
  margin-bottom: 8px;
}

.float-end {
  float: right;
  width: initial;
}

// User Timeline Card
.card-user-timeline {
  .user-timeline-title-icon {
    width: 1.714rem;
    height: 1.714rem;
    margin-right: 1.3rem;
  }
  .timeline {
    .timeline-item {
      &:last-child {
        .timeline-event {
          min-height: auto;
        }
      }
      &:not(:last-child) {
        padding-bottom: 2.3rem;
      }
    }
  }
}

// Business Card
.business-card {
  .business-items {
    &:not(:last-child) {
      margin-bottom: 1.3rem;
    }

    .business-item {
      border: 1px solid $border-color;
      border-radius: $border-radius;
      padding: 1rem 1rem 1rem 1.3rem;
      &:not(:last-child) {
        margin-bottom: 0.85rem;
      }
    }
  }
}

// States Card
.card-browser-states {
  .browser-states {
    margin-top: 2.14rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

// App Design Card
.card-app-design {
  .design-group {
    margin-bottom: 2rem;

    .avatar:not(:last-child) {
      margin-right: 0.6rem;
    }
  }

  .design-planning-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    &:not(:last-child) {
      margin-bottom: 1.3rem;
    }

    .design-planning {
      padding: 0.5rem;
      margin-bottom: 0.7rem;
      text-align: center;
      background-color: $body-bg;
      border-radius: $border-radius;
      min-width: 6.4rem;
      &:not(:last-child) {
        margin-right: 0.7rem;
      }
    }
  }
}

//--------------- Statistics Card ---------------
//------------------------------------------------

.card-statistics {
  .card-header {
    padding-left: 2.4rem;
  }
  .statistics-body {
    padding: 2rem 2.4rem 2.8rem !important;

    .avatar .avatar-content {
      width: 48px;
      height: 48px;

      .avatar-icon {
        width: 24px;
        height: 24px;
      }
    }
  }
}

//--------------- Analytics Card ---------------
//----------------------------------------------

// Chart-dropdown Button
.chart-dropdown {
  .btn {
    font-size: 1rem;
    font-weight: $font-weight-normal;
    &:after {
      display: none;
    }
  }
}

// Revenue Report & Budget Card
.card-revenue-budget {
  .revenue-report-wrapper {
    padding: 1.286rem 1.5rem;

    @include media-breakpoint-up(md) {
      border-right: 1px solid $border-color;
    }

    #revenue-report-chart {
      .apexcharts-series {
        &[seriesName="Earning"] {
          transform: scaleY(0.965);
        }
        &[seriesName="Expense"] {
          transform: scaleY(1.035);
        }
      }
    }
  }

  .budget-wrapper {
    padding: 2rem 3rem;
    text-align: center;

    @include media-breakpoint-down(sm) {
      padding-top: 0;
    }

    .budget-dropdown {
      margin-bottom: 2rem;
    }

    #budget-chart {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }
}

// Earnings Card
.earnings-card {
  .apexcharts-canvas .apexcharts-pie {
    .apexcharts-datalabel-label {
      font-size: 0.8rem;
      fill: $body-color !important;
    }
    .apexcharts-datalabel-value {
      fill: $headings-color;
      font-size: 1.286rem;
      font-weight: 500;
    }
  }
}

// IE Specific CSS
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  // make icon center in avatar-content
  #dashboard-analytics,
  #statistics-card {
    .avatar {
      .avatar-content {
        padding: 0 !important;
      }
    }
  }
}
