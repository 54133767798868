@import "@styles/base/bootstrap-extended/_include.scss";
@import "@styles/base/components/include";

.dark-layout {
  .gauge-container {
    .normal-text-color {
      color: $theme-dark-body-color;
    }
    .info-icon {
      color: $theme-dark-body-color;
    }
  }
}

.gauge-container {
  border: 1px solid $border-color;
  .normal-text-color {
    color: $body-color;
  }
  .info-icon {
    color: $body-color;
  }
}
